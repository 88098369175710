import { atom } from "recoil";

//유저 정보
export const setUserAtom = atom({
  key: "users",
  default: [],
});

//술기 정보
export const setExamAtom = atom({
  key: "exam",
  default: {
    list: [],
    version: 0,
  },
});

//배점 정보
export const setPointAtom = atom({
  key: "points",
  default: [],
});

//선택 여부 확인
export const isSelectAtom = atom({
  key: "isSelected",
  default: "",
});

export const setSelectedTitleAtom = atom({
  key: "selectedTitle",
  default: "",
});

export const setSelectedRecordTitleAtom = atom({
  key: "selectedRecordTitle",
  default: "",
});

export const setSelectedTypeAtom = atom({
  key: "selectedExcelExportTyle",
  default: "ALL",
});

export const setSelectedPointAtom = atom({
  key: "selectedPointTitle",
  default: {
    title: "",
    score_setting: "",
  },
});

export const setFiltersDataAtom = atom({
  key: "filterData",
  default: [],
});

export const isFilteringAtom = atom({
  key: "isFltering",
  default: false,
});

export const isChangedInputTextAtom = atom({
  key: "inputTextChange",
  default: false,
});

export const setChangePointAtom = atom({
  key: "changePoints",
  default: [],
});

export const setLanguage = atom({
  key: "language",
  default: "한국어",
});
