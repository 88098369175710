/* eslint-disable no-unused-vars */
import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import NotFound from "pages/NotFound";
import LoginPage from "pages/Auth/LoginPage";

const Auth = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate replace to="login" />} />
        <Route path="login" element={<LoginPage />} />
        {/* <Route path="register" element={<RegisterPage />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Outlet />
    </>
  );
};

export default Auth;
