import styled from "styled-components";

export const Nav = styled.nav`
  display: flex;
  position: fixed;
  background-color: #07193d;
  color: #fff;
  width: 100%;
  height: 50px;
  align-items: center;
  z-index: auto;

  @media (max-width: 880px) {
    justify-content: space-around;
    top: 0;
    left: 0;
  }

  @media (min-width: 880px) {
    top: 0;
    left: 0;
    height: 100%;
    width: 400px;
    flex-direction: column;
    align-items: center;
  }
`;

export const NavItemList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  height: 100%;

  @media (max-width: 880px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @media (min-width: 880px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const NavProfile = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 880px) {
    display: ${(props) => (props.isManagerForm ? "none" : "block")};
  }
`;
