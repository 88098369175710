import React from "react";
import { useNavigate } from "react-router-dom";
import { Item, ItemText } from "./Styled";

const NavItem = (props) => {
  const lastPathname = window.location.pathname.split("/").pop();
  const navigate = useNavigate();
  const handleItemClick = (path) => {
    navigate(`/manager/${path}`);
  };

  const handleClick = (path) => {
    handleItemClick(path);
  };

  return (
    <Item
      onClick={() => handleClick(props.path)}
      path={props.path === lastPathname}
    >
      <ItemText>
        {props.icon}
        {props.text}
      </ItemText>
    </Item>
  );
};

export default NavItem;
