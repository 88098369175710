import axios from "axios";

export const apiURI = process.env.REACT_APP_API_SERVER || "";

export async function downloadManual() {
  try {
    const response = await axios.get(`${apiURI}/manual`, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function postLogin(formData) {
  const response = await axios.post(apiURI + "/users/login", formData, {
    headers: { "Content-Type": "application/json" },
  });
  if (!response.data) throw new Error("error");
  return response.data;
}

export async function getProfile(id) {
  const response = await axios.get(apiURI + `/users/profile/${id}`);
  if (!response.data) throw new Error("error");
  return response.data;
}

export async function getUsers() {
  const response = await axios.get(apiURI + `/stud/students`);
  if (!response) return null;
  return response.data;
}

export async function patchStudents(changeItem) {
  await axios.patch(apiURI + "/stud/students", { changeItem });
}

export async function postStudents(convert) {
  await axios.post(apiURI + "/stud/students", { convert });
}

export async function getExamSelected(title) {
  const response = await axios.get(apiURI + `/contants/exam/${title}`);
  if (!response) return null;
  return response.data;
}

export async function getExamDetail(id) {
  const response = await axios.get(apiURI + `/contants/detail/${id}`);
  if (!response) return null;
  return response.data;
}


export async function getRecordingUrl(recordingFile) {
  return `${apiURI}/contants/getRecording/${recordingFile}`;
}

export async function patchExamDetail(params) {
  await axios.patch(apiURI + `/contants/examPoint`, { params });
}

export async function patchComments(params) {
  await axios.patch(apiURI + "/contants/comment", {
    params,
  });
}


export async function getExamPoints() {
  const response = await axios.get(apiURI + `/contants/score`);
  return response.data;
}

export async function patchExamPoints(params) {
  await axios.patch(apiURI + `/contants/score`, {
    params,
  });
}

export async function getExam() {
  const response = await axios.get(apiURI + "/contants/exam");
  if (!response) return null;
  return response.data;
}


export async function getRecordSelected(title) {
  const response = await axios.get(apiURI + `/records/exam/${title}`);
  if (!response) return null;
  return response.data;
}

export async function getRecordDetail(id) {
  const response = await axios.get(apiURI + `/records/detail/${id}`);
  if (!response) return null;
  return response.data;
} 

export async function patchRecordDetail(params) {
  await axios.patch(apiURI + `/records/score`, {
    params,
  });
}

export async function patchRecordComments(params) {
  await axios.patch(apiURI + "/records/comment", {
    params,
  });
}