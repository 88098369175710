import React, { useState } from "react";
import { ko } from "date-fns/esm/locale";
import { TITLE_MAPPING } from "services/mapping";
import { useRecoilState, useRecoilValue } from "recoil";
import { setSelectedTitleAtom, setSelectedTypeAtom } from "services/atom";
import Radio from "components/Common/Input/Radio";
import RadioGroup from "components/Common/Input/Radio/RadioGroup";
import { t } from "i18next";
import { StyledExcelIcon } from "assets/images/icon/Svg";
import { DownloadExcel } from "services/ExcelHandler";
import "react-datepicker/dist/react-datepicker.css";
import { ItemButton } from "components/Common/Button/CustomButton";
import AlertDialog from "components/Common/Dialog";
import { format } from "date-fns";
import {
  DateBox,
  Notification,
  RadioBox,
  SaveButton,
  SelectDate,
} from "./Styled";

const NEW_PROPERTY_NAMES = {
  name: t("Name"),
  studentid: t("StudentID"),
  exam_class: t("CoreSkills"),
  score_setting: t("ScoreSetting"),
  totalScore: t("TotalScore"),
  exam_time: t("TestTime"),
};

//사이 값 찾기
const inRange = (value, rangeStart, rangeEnd) => {
  return (value - rangeStart) * (value - rangeEnd) <= 0;
};

const transformJsonData = (obj, title) => {
  const item = obj.map((item) => {
    const {
      name,
      studentid,
      exam_class,
      score_setting,
      totalScore,
      exam_time,
      score_data_array,
    } = item;
    return {
      name,
      studentid,
      exam_class,
      score_setting,
      totalScore: totalScore || 0,
      exam_time,
      score_data_array,
    };
  });

  //key 순서 변경

  const oldItem = item.map((item) => {
    const transformedItem = {};
    for (const key in item) {
      const newKey = NEW_PROPERTY_NAMES[key] || key;
      transformedItem[newKey] = item[key];
    }
    return transformedItem;
  });

  const newItem = oldItem.map((item) => {
    item.score_data_array.map((data, index) => {
      return (item[`${index + 1}_${t(`exam:${title}:${data.itemName}`)}`] =
        data.score);
    });
    delete item.score_data_array;
    return item;
  });
  return newItem;
};

const DateExcel = ({ excelData = {}, visible }) => {
  const { code } = JSON.parse(localStorage.getItem("lng"));
  const [isModalOpen, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());
  const [checkedType, setType] = useRecoilState(setSelectedTypeAtom);
  const title = useRecoilValue(setSelectedTitleAtom);

  const EXCEL_EXPORT_ERROR_MESSAGE = t("FailExport");
  const EXCEL_EXPORT_SUCCESS_MESSAGE = t("SuccessExport");

  //title 한글명 가져오기
  const selected = TITLE_MAPPING.filter((data) => data.engOption === title);

  const handleSubmit = () => {
    //날짜 우선 탐색
    let obj = excelData.filter((data) => {
      let _startDate = format(new Date(startDate), "yyyy/MM/dd 00:00:00");
      let _endDate = format(new Date(endDate), "yyyy/MM/dd 23:59:59");

      return inRange(
        new Date(format(new Date(data.exam_time), "yyyy/MM/dd HH:mm:ss")),
        checkedType === "ALL" ? new Date(0) : new Date(_startDate),
        new Date(_endDate)
      );
    });

    //데이터 존재 유무 확인
    if (0 < obj.length) {
      const transFormData = transformJsonData(obj, title);
      DownloadExcel(transFormData, title);
      setMessage(EXCEL_EXPORT_SUCCESS_MESSAGE);
    } else {
      setMessage(EXCEL_EXPORT_ERROR_MESSAGE);
    }
  };

  const toggleModal = () => {
    setOpen(!isModalOpen);
    setMessage("");
  };

  React.useEffect(() => setType("ALL"), [setType]);
  return (
    <>
      <ItemButton visible={visible} onClick={toggleModal}>
        <StyledExcelIcon />
        <span>excel</span>
      </ItemButton>
      <AlertDialog
        open={isModalOpen}
        handleModal={toggleModal}
        title={selected[0]?.korOption}
      >
        <RadioBox>
          <RadioGroup label={"Save"}>
            <Radio
              label={t("Every")}
              name="contact"
              value="ALL"
              defaultChecked
            />
            <Radio label={t("DateRange")} name="contact" value="DATE" />
          </RadioGroup>
        </RadioBox>
        <DateBox>
          <SelectDate
            locale={code === "ko" && ko}
            onChange={(date) => setStartDate(date)}
            selected={startDate}
            startDate={new Date(0)}
            endDate={endDate}
            disabled={checkedType === "ALL" ? true : false}
            selectsStart
          />
          <SelectDate
            locale={code === "ko" && ko}
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            disabled={checkedType === "ALL" ? true : false}
            selectsEnd
          />
        </DateBox>
        <Notification>
          <span id={"message"}>{message}</span>
          <SaveButton variant="outlined" onClick={handleSubmit}>
            {t("Save")}
          </SaveButton>
        </Notification>
      </AlertDialog>
    </>
  );
};

export default DateExcel;
