import React, { useState } from "react";
import { Box, Button, Modal, TextField, IconButton } from "@material-ui/core";
import { CheckCircleOutline } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import { patchRecordComments } from "services/api";
import { t } from "i18next";
import { close, style, useStyles } from "./Styled";
import { useMutation } from "react-query";

const buttonTextByViewState = {
  true: "Views",
  false: "Create",
};

/**
 * 게시글에서 댓글을 작성하거나 댓글을 확인할 수 있는 버튼을 렌더링하는 컴포넌트입니다.
 *
 * @param {*} comment - 댓글 내용
 * @param {*} state - 댓글이 이미 작성되어 있는지 여부를 나타내는 boolean 값
 * @param {*} index - 댓글의 인덱스
 */
const RecordFeedButton = ({ comment, state, index }) => {
  let { id } = useParams();
  const [open, setOpen] = useState(false);
  const [isData, setIsData] = useState(state);
  const [commentText, setComment] = useState("");

  const classes = useStyles(isData);
  const text = buttonTextByViewState[isData];

  const { mutate } = useMutation(
    () =>
      patchRecordComments({
        number: id,
        index: index,
        comment: commentText,
      }),
    {
      onSuccess: () => {
        setIsData(true);
        setOpen(false);
      },
    }
  );

  const handleModal = () => {
    setOpen(!open);
  };
  const handleCommentChange = (e) => {
    const { value } = e.target;
    setComment(value);
  };
  const saveComment = () => {
    mutate();
  };

  return (
    <>
      <Button onClick={handleModal} className={classes.btn} variant="outlined">
        {text}
      </Button>
      <Modal open={open} onClose={handleModal}>
        <Box sx={style}>
          <div>
            <TextField
              className={classes.textField}
              onChange={handleCommentChange}
              placeholder={t("WriteComment")}
              multiline
              fullWidth
              minRows={1}
              maxRows={4}
              InputProps={{
                style: {
                  fontSize: "2rem",
                },
              }}
            />
            {comment && <span>{comment}</span>}
          </div>
          <Box sx={close}>
            <IconButton onClick={saveComment}>
              <CheckCircleOutline />
            </IconButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default RecordFeedButton;
