const { default: styled } = require("styled-components");

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* @media (max-width: 880px) {
    align-items: center;
  } */
`;

export const ButtonBox = styled.div`
  display: flex;
  gap: 10px;
  top: 100%;
`;

export const Filter = styled.div`
  position: relative;
  border: none;
  border-bottom: 1px;
  /* input[type="text"] {
    width: 200px;
    padding: 8px;
    &:focus {
      outline: none;
    }
  } */
`;
