import { getProfile, postLogin } from "services/api";
import moment from "moment/moment";
import "moment/locale/ko";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useMutation } from "react-query";
import Auth from "components/Auth";

const loginTime = moment().format("LLL");

const LoginPage = () => {
  const [errorMessage, setError] = useState("");
  const [formData, setFormData] = useState({
    user: "",
    password: "",
  });

  const navigate = useNavigate();

  const loginMutation = useMutation(postLogin, {
    onSuccess: async (login) => {
      if (formData.user !== login.user) {
        throw new Error("Invalid username or password");
      }

      try {
        const response = await getProfile(login._id);
        sessionStorage.setItem("id", JSON.stringify(response));

        alert(t("LoginAlert", { loginTime }));

        navigate("/manager/admin");
      } catch (error) {
        throw new Error("error");
      }
    },
  });

  const handleLogin = async () => {
    try {
      if (!formData.user || !formData.password) {
        throw new Error("Please enter your username and password");
      }

      await loginMutation.mutateAsync(formData);
    } catch (error) {
      setError(t("AccountError"));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const onPressEnter = (e) => {
    e.key === "Enter" && handleLogin();
  };

  return (
    <Auth
      type={"login"}
      handleLogin={handleLogin}
      handleSubmit={onPressEnter}
      handleChange={handleInputChange}
      errorMessage={errorMessage}
    />
  );
};

export default LoginPage;
