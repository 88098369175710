import { t } from "i18next";
import React from "react";
import styled from "styled-components";

const Text = styled.p`
  margin: 0;
  @media (max-width: 880px) {
    font-size: 0.8rem;
  }
`;
const Name = ({ isManagerForm }) => {
  const getSession = JSON.parse(sessionStorage.getItem("id"));
  return (
    <Text isManagerForm={isManagerForm}>
      {t("Welcome", { account: getSession.name })}
    </Text>
  );
};

export default Name;
