import React from "react";
import styled from "styled-components";
import LogoutButton from "../Button/LogoutButton";
import Name from "./Name";

const Container = styled.div`
  margin: 2rem auto;
  display: flex;
  & p {
    text-align: center;
  }
  flex-direction: column;
  align-items: center;
  @media (max-width: 880px) {
    display: none;
  }
`;

const AvatarIcon = styled.div`
  margin: 0.5rem auto;
  padding: 1rem;
  display: flex;
  justify-content: space-around;
  & img {
    width: 104px;
    height: 104px;
  }
`;

const Profile = () => {
  return (
    <Container>
      <AvatarIcon>
        <img src={`${process.env.PUBLIC_URL}/Profile.png`} alt="profile" />
      </AvatarIcon>
      <Name />
      <LogoutButton />
    </Container>
  );
};

export default Profile;
