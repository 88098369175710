import styled from "styled-components";

export const TooltipText = styled.span`
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
`;
export const TooltipBox = styled.div`
  display: inline-block;
  font-size: 14px;
  width: 200px;
  position: absolute;
  top: calc(100% + 2px);
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  padding: 5px 5px;
  border-radius: 4px;
  white-space: pre-line;
  transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;
`;

export const TooltipCard = styled.div`
  && {
    text-align: center;
    justify-content: center;
    display: flex;
    position: relative;
  }
  & ${TooltipText}:hover + ${TooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    display: inline-block;
    padding: 8px 8px;
    border-radius: 4px;
  }
`;
