import { Tooltip } from "components/Common/Tooltip";
import { format } from "date-fns";
import { t } from "i18next";
import { Link } from "react-router-dom";
import FeedButton from "../Button/FeedButton";
import RecordFeedButton from "../Button/FeedButton/recordFeed";
import Recording from "../Recording";
import { InputTextCell, InputValueCell, SelectCell } from "./Cell";
import { FILTER_OPTION } from "services/mapping";

export const ADMIN_COLUMNS = [
  {
    accessor: "name",
    Header: () => t("Name"),
  },
  {
    accessor: "studentid",
    Header: () => t("StudentID"),
  },
  {
    accessor: "grade",
    Header: () => t("Grade"),
    disableGlobalFilter: true,
  },
  {
    accessor: "class",
    Header: () => t("Class"),
    disableGlobalFilter: true,
  },
  {
    accessor: "academicStatus",
    Header: () => t("AcademicStatus"),
    disableGlobalFilter: true,
  },
  {
    accessor: "comment",
    Header: () => t("Comment"),
    disableGlobalFilter: true,
    disableSortBy: true,
    Cell: ({ value }) => {return value && value.length ? value : t("None");},
  },
];

export const ADMIN_EDIT_COLUMNS = [
  {
    accessor: "name",
    Header: () => t("Name"),
  },
  {
    accessor: "studentid",
    Header: () => t("StudentID"),
  },
  {
    accessor: "grade",
    Header: () => t("Grade"),
    Cell: ({ item, column, value, onChange }) => {
      return (
        <SelectCell
          value={value}
          name={"grade"}
          data={[1, 2, 3, 4]}
          index={column}
          onChange={onChange(item)}
        />
      );
    },
  },
  {
    accessor: "class",
    Header: () => t("Class"),
    Cell: ({ item, column, value, onChange }) => {
      return (
        <SelectCell
          value={value}
          name={"class"}
          data={[1, 2, 3, 4]}
          index={column}
          onChange={onChange(item)}
        />
      );
    },
  },
  {
    accessor: "academicStatus",
    Header: () => t("AcademicStatus"),
    Cell: ({ item, column, value, onChange }) => {
      return (
        <SelectCell
          value={t(value)}
          name={"academicStatus"}
          data={FILTER_OPTION.academicStatus}
          index={column}
          onChange={onChange(item)}
        />
      );
    },
  },
  {
    accessor: "comment",
    Header: () => t("Comment"),
    Cell: ({ value, item, onChange }) => (
      <InputTextCell
        name={"comment"}
        initValue={value}
        onChange={onChange(item)}
      />
    ),
  },
];

export const SCORE_COLUMNS = [
  {
    accessor: "name",
    Header: () => t("Name"),
    Cell: ({ value }) => {
      return value ? value : t("Unregistered");
    },
  },
  {
    accessor: "studentid",
    Header: () => t("StudentID"),
  },
  {
    accessor: "totalScore",
    Header: () => t("TotalScore"),
    Cell: ({ value }) => (value ? value : 0),
  },
  {
    accessor: "exam_time",
    Header: () => t("TestTime"),
    Cell: ({ value }) => {
      return format(new Date(value), "yyyy/MM/dd HH:mm:ss");
    },
  },
  {
    accessor: "_id",
    Header: () => t("Detail"),
    Cell: ({ value }) => {
      return <Link to={`${value}`}>{t("More")}</Link>;
    },
  },
];

export const RECORD_COLUMNS = [
  {
    accessor: "name",
    Header: () => t("Name"),
    Cell: ({ value }) => {
      return value ? value : t("Unregistered");
    },
  },
  {
    accessor: "studentid",
    Header: () => t("StudentID"),
  },
  {
    accessor: "exam_time",
    Header: () => t("TestTime"),
    Cell: ({ value }) => {
      return format(new Date(value), "yyyy/MM/dd HH:mm:ss");
    },
  },
  {
    accessor: "_id",
    Header: () => t("Detail"),
    Cell: ({ value }) => {
      return <Link to={`${value}`}>{t("More")}</Link>;
    },
  },
];

export const POINT_COLUMNS = [
  {
    accessor: "itemIndex",
    Header: "No.",
    Cell: ({ value }) => value + 1,
  },
  {
    accessor: "itemName",
    Header: () => t("Item"),
    Cell: ({ value, title }) => {
      return t(`exam:${title}:${value}`);
    },
  },
  {
    accessor: "maxScore",
    Header: () => {
      const Title = t("Score");
      const text = t("ModifyPointsInstruction");
      return <Tooltip title={Title} text={text} />;
    },
    Cell: ({ value, row }) => {
      return (
        <InputValueCell
          value={value}
          originalValue={value}
          index={row.id}
          max={2}
        />
      );
    },
  },
];

export const READ_POINT_COLUMNS = [
  {
    accessor: "itemIndex",
    Header: "No.",
    Cell: ({ value }) => value + 1,
  },
  {
    accessor: "itemName",
    Header: () => t("Item"),
    Cell: ({ value, title }) => {
      return t(`exam:${title}:${value}`);
    },
  },
  {
    accessor: "maxScore",
    Header: () => {
      const Title = t("Score");
      const text = t("ModifyPointsInstruction");
      return <Tooltip title={Title} text={text} />;
    },
    Cell: ({ value }) => <span style={{ width: "100%" }}>{value}</span>,
  },
];

export const EVAL_SCORE_COLUMNS = [
  {
    accessor: "itemIndex",
    Header: "No.",
    Cell: ({ value }) => value + 1,
  },
  {
    accessor: "itemName",
    Header: () => t("Item"),
    Cell: ({ value, title }) => {
      return t(`exam:${title}:${value}`);
    },
  },
  {
    accessor: "score",
    Header: () => {
      const Title = t("Points");
      const text = t("ModifyPointsInstruction");
      return <Tooltip title={Title} text={text} />;
    },
    Cell: ({ value, row }) => {
      return (
        <InputValueCell
          value={value}
          index={row.id}
          originalValue={value}
          max={row.original.maxScore || 0}
        />
      );
    },
  },
  {
    Header: () => t("Score"),
    accessor: "maxScore",
  },
  {
    Header: () => t("FeedBack"),
    accessor: "comment",
    Cell: (props) => {
      console.log(props.row);
      return (
        <FeedButton
          state={!!props.value}
          comment={props.value}
          index={props.row.index}
        />
      );
    },
  },
  {
    accessor: "recordingFile",
    Header: () => t("AudioRecording"),
    Cell: (props) => {
      const { recordingFile, isRecordingFile } = props.row.original;
      const recording = props.value;
      return isRecordingFile && recordingFile ? (
        <Recording recordingIndex={props.row.id} recordingFile={recording} />
      ) : (
        t("ErrorMessage.NotExistRecording")
      );
    },
  },
];


export const EVAL_RECORDING_COLUMNS = [
  {
    accessor: "itemIndex",
    Header: "No.",
    Cell: ({ value }) => value + 1,
  },
  {
    accessor: "itemName",
    Header: () => t("Item"),
    Cell: ({ value, title }) => {
      return t(`record:${title}:${value}`);
    },
  },
  {
    Header: () => t("FeedBack"),
    accessor: "comment",
    Cell: (props) => {
      console.log(props.row);
      return (
        <RecordFeedButton
          state={!!props.value}
          comment={props.value}
          index={props.row.index}
        />
      );
    },
  },
  {
    accessor: "recordingFile",
    Header: () => t("AudioRecording"),
    Cell: (props) => {
      const { recordingFile, isRecordingFile } = props.row.original;
      const recording = props.value;
      return isRecordingFile && recordingFile ? (
        <Recording recordingIndex={props.row.id} recordingFile={recording} />
      ) : (
        t("ErrorMessage.NotExistRecording")
      );
    },
  },
];
