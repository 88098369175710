import { saveAs } from "file-saver";
import { t } from "i18next";
import * as XLSX from "xlsx";

export const DownloadExcel = (jsonData, title = "") => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const workSheet = XLSX.utils.json_to_sheet(jsonData);

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(workBook, {
    bookType: "xlsx",
    type: "array",
  });
  const data = new Blob([excelBuffer], { type: fileType });
  const excelName = title !== "ManageStudent" ? t(`Title.${title}`) : t(title);
  if (data) {
    saveAs(data, `${excelName}_${Date.now()}.xlsx`);
  }
};
