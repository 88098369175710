import styled from "styled-components";
import { FaSearch } from "react-icons/fa";

export const SearchContainer = styled.div`
  position: relative;
`;

export const SearchInput = styled.input`
  padding-left: 24px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #676d8380;
  border-radius: 14px;
  opacity: 1;
  width: 347px;
  height: 27px;
  &:focus {
    padding-left: 24px;
    outline: none;
  }
  &:hover {
    background: #efefef;
  }
  &::value {
    padding-left: 24px;
  }
  @media (max-width: 1150px) {
    width: 250px;
  }
`;

export const SearchIcon = styled(FaSearch)`
  position: absolute;
  right: 23px;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
`;

export const StyledSelectBox = styled.section`
  width: auto;
  height: 40px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  label {
    text-align: left;
    white-space: nowrap;
    flex: 1;
  }
  }
  select {
    display: flex;
    align-items: center;
    width: 80px;
    height: 20px;
    padding-left: 5px;
    font-size: 12px;
    option:first-child {
      font-weight: bold;
    }
  }
`;
