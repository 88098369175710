import { setSelectedTypeAtom } from "services/atom";
import { useRecoilState } from "recoil";

function Radio({ label, value, name }) {
  const [option, setOption] = useRecoilState(setSelectedTypeAtom);

  const handleChange = (e) => {
    setOption(e.target.value);
  };
  return (
    <label>
      {label}
      <input
        type="radio"
        value={value}
        name={name}
        checked={value === option}
        onChange={handleChange}
      />
    </label>
  );
}

export default Radio;
