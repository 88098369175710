import styled from "styled-components";

const color = {
  pink: "#d60983",
  lightPink: "#f8c6d780",
  darkNavy: "#01102E",
};

export const Item = styled.li`
  display: flex;
  margin-right: 10px;
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
  background-color: ${(props) => props.path && `${color.darkNavy}`};
  @media (max-width: 880px) {
    margin-right: 10px;
    margin-left: 10px;
    padding: 10px;
    box-shadow: ${(props) =>
      props.path && `0px -5px 0px 0px ${color.pink} inset`};
  }
  @media (min-width: 880px) {
    display: flex;
    width: 100%;
    margin: 0;
    height: 5.3rem;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    box-shadow: ${(props) =>
      props.path && `-10px 0px 0px 0px ${color.pink} inset`};
  }
`;

export const ItemText = styled.span`
  display: flex;
  width: calc(50% - 2.4rem - 20px);
  align-items: center;
  white-space: nowrap;
  justify-content: space-around;
  @media (min-width: 880px) {
    font-size: 1.5rem;
  }
  @media (max-width: 880px) {
    justify-content: flex-start;
    padding-left: 0;
    font-size: 0.8rem;

    svg {
      display: none;
    }
  }
`;
