import styled from "styled-components";

const RadioBox = styled.article`
  display: flex;
  label {
    margin-right: 2rem;
  }
`;
function RadioGroup({ label, children, ...rest }) {
  return (
    <RadioBox>
      <fieldset>{children}</fieldset>
    </RadioBox>
  );
}

export default RadioGroup;
