import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { StyledCloseIcon } from "assets/images/icon/Svg";
import styled from "styled-components";

const Contents = styled(DialogContent)`
  height: 30rem;
`;

const CloseIcon = styled(StyledCloseIcon)`
  position: absolute;
  top: 10px;
  cursor: pointer;
`;

const AlertDialog = ({ open, handleModal, title, children }) => {
  return (
    <Dialog open={open} onClose={handleModal}>
      <DialogTitle>{title}</DialogTitle>
      <Contents>{children}</Contents>
      <DialogActions>
        <CloseIcon onClick={handleModal} />
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
