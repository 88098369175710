import { useContext } from "react";
import { ItemButton } from "components/Common/Button/CustomButton";
import { GlobalFilter, SelectColumnFilter } from "components/Common/Filter";
import Dropdown from "components/Common/Dropdown";
import { ButtonBox, Container, Filter } from "./Styled";
import { t } from "i18next";
import {
  StyledBackIcon,
  StyledExcelIcon,
  StyledFilterIcon,
  StyledSaveIcon,
  StyledUpdateIcon,
} from "assets/images/icon/Svg";
import ImportExcel from "components/Manager/Excel/ImportExcel";
import ExportExcel from "components/Manager/Excel/ExportExcel";
import { AdminContext } from "pages/Manager/Admin";
import { FILTER_OPTION } from "services/mapping";

const AdminHeader = () => {
  const props = useContext(AdminContext);
  return (
    <Container>
      <Filter>
        {!props.isUpdate && (
          <GlobalFilter filter={props.global} setFilter={props.setGlobal} />
        )}
      </Filter>
      <ButtonBox>
        <ItemButton onClick={props.handleSaving}>
          {props.isUpdate ? <StyledSaveIcon /> : <StyledUpdateIcon />}
          <span>{props.isUpdate ? "Save" : "Update"}</span>
        </ItemButton>
        {props.isUpdate && (
          <ItemButton onClick={props.handleBack}>
            <StyledBackIcon />
            <span>Back</span>
          </ItemButton>
        )}
        {!props.isUpdate && (
          <>
            <Dropdown
              Icon={StyledExcelIcon}
              title={"Excel"}
              disabled={props.isUpdate}
              isHover={true}
            >
              <ImportExcel />
              <ExportExcel />
            </Dropdown>
            <Dropdown
              Icon={StyledFilterIcon}
              title={"Filter"}
              disabled={props.isUpdate}
              isHover={false}
            >
              {Object.keys(FILTER_OPTION).map((filterName) => {
                const toUpper =
                  filterName.charAt(0).toUpperCase() + filterName.slice(1);
                return (
                  <SelectColumnFilter
                    key={filterName}
                    filterValue={props.filters[filterName]}
                    setFilter={props.handleFilterChange}
                    options={FILTER_OPTION[filterName]}
                    title={t(`${toUpper}`)}
                    name={filterName}
                  />
                );
              })}
            </Dropdown>
          </>
        )}
      </ButtonBox>
    </Container>
  );
};

export default AdminHeader;
