import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const PrivateRoute = () => {
  const isLogin = sessionStorage.getItem("id") !== null;

  return isLogin ? (
    <Outlet />
  ) : (
    <Navigate to={{ pathname: "/auth", state: { from: "/" } }} />
  );
};
