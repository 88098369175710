import { css } from "styled-components";

export const buttonStyles = css`
  display: inline-flex;
  height: 2.5rem;
  font-size: 1.4rem;
  font-weight: 700;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 0.5rem;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  box-shadow: 1px 1px 2px #b9c0dd99;
  background: white;
  &:active {
    background: #f5f6fa;
  }
`;
