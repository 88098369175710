import React from "react";
import { StyledTooltipIcon } from "assets/images/icon/Svg";
import { TooltipBox, TooltipCard, TooltipText } from "./Styled";

export const Tooltip = (props) => {
  return (
    <TooltipCard>
      <TooltipText className={props.styles}>
        {props.title}
        <StyledTooltipIcon />
      </TooltipText>
      <TooltipBox>
        <span>{props.text}</span>
      </TooltipBox>
    </TooltipCard>
  );
};
