import React from "react";
import styled from "styled-components";

const InputBlock = styled.div`
  display: flex;
  background: #f5f7fb;
  margin-bottom: 20px;
  width: 500px;
  height: 60px;
  &:focus-within {
    border: 2px solid #909494;
  }
`;
const InputText = styled.input`
  border: 2px solid #f5f7fb;
  line-height: 30px;
  font-size: 20px;
  width: 100%;
  outline: none;
`;

const InputIcon = styled.i`
  top: 0;
  width: 60px;
  height: 100%;
  font-size: 25px;
  color: #909494;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconTextField = ({ children, ...prev }) => {
  return (
    <InputBlock>
      <InputIcon>{children}</InputIcon>
      <InputText {...prev} />
    </InputBlock>
  );
};

export default IconTextField;
