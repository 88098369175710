import React from "react";
import PropTypes from "prop-types";
import { Item, ItemList, ItemWrapper } from "./Styled";
import { ItemButton } from "../Button/CustomButton";
import { useClickOutside } from "services/hooks/useClickOutSide";

const Dropdown = ({ children, Icon, title, disabled, isHover }) => {
  const [visible, setVisible] = React.useState(false);
  const localeRef = React.useRef();

  const handleButtonClick = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    setVisible(!visible);
  };

  useClickOutside(localeRef, () => {
    setVisible(false);
  });

  return (
    <ItemWrapper disabled={disabled} ref={localeRef}>
      <ItemButton onClick={handleButtonClick} active={visible}>
        <Icon />
        <span>{title}</span>
      </ItemButton>
      <ItemList visible={visible}>
        {children.map((child, index) => {
          return (
            <Item isHover={isHover} key={index}>
              {child}
            </Item>
          );
        })}
      </ItemList>
    </ItemWrapper>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node,
  Icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default Dropdown;
