import styled from "styled-components";
import { buttonStyles } from "./Styled";

export const ItemButton = styled.button`
  ${buttonStyles}
  height: 50px;
  width: 130px;
  font-size: 1.6rem;
  background-color: ${(props) => props.active && "#081D47"};
  color: ${(props) => props.active && "white"};
  path {
    fill: ${(props) => props.active && "white"};
  }

  &:hover,
  &:hover path {
    background-color: #081d47;
    fill: white;
    color: white;
  }

  &:disabled {
    background-color: lightgray;
  }

  @media (max-width: 1250px) {
    width: 100px;
  }

  @media (max-width: 1080px) {
    width: 50px;
    height: 50px;
    span {
      display: none;
    }
  }
`;

export const LocaleButton = styled.button`
  ${buttonStyles}
  min-width: 10rem;
  background-color: ${(props) => (props.active ? "#081D47" : "#ffffff")};
  color: ${(props) => props.active && "white"};

  path {
    fill: ${(props) => props.active && "white"};
  }
  span {
    display: flex;
    align-items: center;
  }
`;

export const ManualButton = styled.button`
  ${buttonStyles}
  width: 123px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-size: 1.4rem;
  min-width: 9.5rem;
  span {
    display: flex;
    align-items: center;
  }
`;
