import { t } from "i18next";
import React, { useState } from "react";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { useClickOutside } from "services/hooks/useClickOutSide";
import {
  Container,
  IconP,
  SelectLabel,
  SelectOption,
  SelectOptions,
  Title,
} from "./Styled";

const Select = ({ value, options, type, onClick, title, small, isLoading }) => {
  const [labelVisible, setLabelVisible] = useState(false);
  const selectRef = React.useRef(null);
  const handleLabelClick = () => {
    setLabelVisible(!labelVisible);
  };

  const handleOptionClick = (data) => {
    onClick(data);
    setLabelVisible(false);
  };

  useClickOutside(selectRef, () => {
    setLabelVisible(false);
  });

  //Container options
  const sizeClass = small && "small";
  const disabled = isLoading;

  return (
    <Container small={sizeClass} disabled={disabled} ref={selectRef}>
      <Title>{title}</Title>
      <SelectLabel onClick={handleLabelClick} htmlFor="select">
      {(value || value === 0) ? (type === "title" ? t(`Title.${value}`) : value) : title}
      <div>
          <IconP>
            <TiArrowSortedUp />
            <TiArrowSortedDown />
          </IconP>
        </div>
      </SelectLabel>
      <SelectOptions id="select" visible={labelVisible}>
        {options?.map((data, index) => {
          return (
            <SelectOption
              key={index}
              selected={value === data}
              onClick={() => handleOptionClick(data)}
            >
              {type === "title" ? t(`Title.${data}`) : t(data)}
            </SelectOption>
          );
        })}
      </SelectOptions>
    </Container>
  );
};

export default Select;
