import React, { useState } from "react";
import { RECORD_COLUMNS } from "components/Common/Table/columns";
import { BasicTable } from "components/Common/Table/BasicTable";
import { useQuery } from "react-query";
import { format } from "date-fns";
import { ErrorCell } from "components/Common/Table/Cell";
import Header from "components/Common/Header";
import { setSelectedRecordTitleAtom } from "services/atom";
import { getRecordSelected } from "services/api";
import { useRecoilState } from "recoil";
import { t } from "i18next";
import ManagerForm from "components/Manager/ManagerForm";
import { RECORD_TITLE_MAPPING } from "services/mapping";
import { Loading } from "components/Common/Loading";
import Select from "components/Common/Select";

const Records = () => {
  const [recordTitle, setTitle] = useRecoilState(setSelectedRecordTitleAtom);
  const { data, isLoading } = useQuery(
    ["Records", recordTitle],
    () => getRecordSelected(recordTitle),
    {
      enabled: !!recordTitle.length,
      refetchOnWindowFocus: false,
    }
  );

  const [isVisible, setVisible] = useState(false);
  const [option, setOption] = useState("default");
  const [filteredData, setFilteredData] = useState([]);

  React.useEffect(() => {
    if (data) {
      //데이터를 필터링하고 정렬
      const filterExam = data
        .sort((a, b) => new Date(b.exam_time) - new Date(a.exam_time))
        .filter((examData) => {
          return examData.exam_class === recordTitle;
        })
        .sort((a, b) => b.exam_time - a.exam_time)
        .map((examData) => {
          const copiedData = { ...examData };
          copiedData.name = examData.name || null;
          copiedData.exam_class = t(`Title.${examData.exam_class}`);
          copiedData.exam_time = format(
            new Date(examData.exam_time),
            "yyyy/MM/dd HH:mm:ss"
          );
          return copiedData;
        });
      setFilteredData(filterExam);
      const isVisible = filterExam.length !== 0;
      setVisible(isVisible);
    }
  }, [data, recordTitle]);

  const handleClick = (data) => {
    setTitle(data);
  };

  React.useEffect(() => {
    setOption(
      recordTitle && filteredData.length === 0 ? "selctedItemNoExist" : "default"
    );
  }, [filteredData, recordTitle]);

  return (
    <ManagerForm
      type={"records"}
      titleText={t("ManageRecord")}
      itemText={t("SearchRecord")}
      titleHeader={
        <Header>
          {!isLoading && (
            <>
              <Select
                value={recordTitle}
                onClick={handleClick}
                options={RECORD_TITLE_MAPPING}
                title={t("CoreSkills")}
                type={"title"}
              />
            </>
          )}
        </Header>
      }
    >
      {isLoading ? (
        <Loading>Loading...</Loading>
      ) : !isLoading && !isVisible ? (
        <ErrorCell option={option} />
      ) : (
        !isLoading && (
          <BasicTable
            columns={RECORD_COLUMNS}
            data={filteredData}
            title={recordTitle}
          />
        )
      )}
    </ManagerForm>
  );
};

export default Records;
