import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { RecoilRoot } from "recoil";
import "./services/i18n";
import { LANGUAGES_SWAP_MAPPING } from "services/mapping";
import Router from "Router";

const queryClient = new QueryClient();

const localLng = localStorage.getItem("lng");
//클라이언트 언어 설정 확인
if (localLng === null) {
  const lang = window.navigator.language;
  const lng = lang.toLowerCase().substring(0, 2);
  localStorage.setItem("lng", JSON.stringify(LANGUAGES_SWAP_MAPPING[lng]));
}

ReactDOM.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  </RecoilRoot>,
  document.getElementById("root")
);
