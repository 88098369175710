import styled from "styled-components";
import DatePicker from "react-datepicker";
import { Button } from "@material-ui/core";

export const Container = styled.div`
  display: flex;
  height: 22px;
  width: auto;
  margin-right: 15px;
  align-items: center;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

export const DateBox = styled.div`
  width: 100%;
  display: ${(props) => (props.type === "ALL" ? "none" : "flex")};
`;

export const SelectDate = styled(DatePicker)`
  position: relative;
  height: 22px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #e5e5e5;
  outline: none;
  cursor: pointer;
`;

export const Notification = styled.div`
  display: flex;
  flex-direction: column;
  span#message {
    position: absolute;
    color: red;
  }
`;
export const SaveButton = styled(Button)`
  top: 200px;
  position: absolute;
`;

export const RadioBox = styled.article`
  display: flex;
  label {
    margin-right: 2rem;
  }
`;
