import { setChangePointAtom } from "services/atom";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";

const StyledInput = styled.input`
  width: 20px;
  border: 0;
  padding: 0;
  cursor: pointer;
  text-align: center;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  color: ${(props) => props.isChanged && "red"};
`;

const EditValue = ({ value, index, max, originalValue }) => {
  const [itemPoints, setPoints] = useRecoilState(setChangePointAtom);
  const [inputValue, setInputValue] = useState(value);
  const [isChanged, setIsChanged] = useState(false);

  React.useEffect(() => {
    setInputValue(value);
    setIsChanged(value !== originalValue);
  }, [value, originalValue]);

  const inputValidation = (value) => {
    const isNumber = /^\d+(.\d+)?$/.test(value);
    if (value === "" || !isNumber) {
      return 0;
    } else if (value > max) {
      return max;
    } else {
      return value;
    }
  };

  const handleBlur = () => {
    setPoints([
      ...itemPoints.filter((item) => item.itemIndex !== index),
      { itemIndex: +index, points: +inputValue },
    ]);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const validatedValue = inputValidation(value);
    setInputValue(validatedValue);
    setIsChanged(validatedValue !== originalValue);
  };

  return (
    <StyledInput
      type="number"
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={(e) => (e.target.value = "")}
      value={inputValue || originalValue}
      isChanged={isChanged}
    />
  );
};

export default EditValue;
