import React, { useState, useEffect } from "react";
import { getRecordingUrl } from "services/api";

const Recording = ({ recordingFile }) => {
  const [audioUrl, setAudioUrl] = useState(null);

  useEffect(() => {
    const fetchAudioUrl = async () => {
      const url = await getRecordingUrl(recordingFile);
      setAudioUrl(url);
    };

    if (recordingFile) {
      fetchAudioUrl();
    }
  }, [recordingFile]);

  if (!audioUrl) {
    return null;
  }

  return (
    <audio controls src={audioUrl}>
      Your browser does not support the <code>audio</code> element.
      <a href={audioUrl}>Download audio</a>
    </audio>
  );
};

export default Recording;
