import NotFound from "pages/NotFound";
import { Admin, Eval, Records ,EvaluationDetail, RecordingDetail , Points } from "pages/Manager";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";

const Contant = () => {
  return (
    <Routes>
      <Route path={"/"} element={<PrivateRoute />}>
        <Route path={"/"} element={<Navigate replace to="admin" />} />
        <Route path={"admin"} element={<Admin />} />
        <Route path={"points"} element={<Points />} />
        <Route path={"eval"} element={<Eval />} />
        <Route path={"records"} element={<Records />} />
        <Route path={"eval/:id"} element={<EvaluationDetail />} />
        <Route path={"records/:id"} element={<RecordingDetail />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Contant;
