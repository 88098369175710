import React, { useMemo, useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { t } from "i18next";
import { getExamPoints, patchExamPoints } from "services/api";
import { TITLE_MAPPING } from "services/mapping";
import {
  POINT_COLUMNS,
  READ_POINT_COLUMNS,
} from "components/Common/Table/columns";
import { useRecoilState } from "recoil";
import {
  setChangePointAtom,
  setExamAtom,
  setSelectedPointAtom,
} from "services/atom";
import { BasicTable } from "components/Common/Table/BasicTable";
import { ErrorCell } from "components/Common/Table/Cell";
import { ItemButton } from "components/Common/Button/CustomButton";
import { StyledUpdateIcon } from "assets/images/icon/Svg";
import Header from "components/Common/Header";
import Select from "components/Common/Select";
import ManagerForm from "components/Manager/ManagerForm";
import { Loading } from "components/Common/Loading";

const ExamPointView = () => {
  //useRecoil
  const [selected, setSelected] = useRecoilState(setSelectedPointAtom); // title, version
  const [itemPoints, setPoints] = useRecoilState(setChangePointAtom);
  const [exam, setExam] = useRecoilState(setExamAtom);
  //useState
  const [columns, setColumns] = useState(POINT_COLUMNS);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [examList, setExamList] = useState([]);
  const [versionOption, setVersionOption] = useState([]);

  //fn
  const filterByTitle = (data, title) => {
    return data?.filter(({ skillName }) => skillName === title) ?? [];
  };
  const latestByExam = (data) => {
    return data.sort((a, b) => b.score_setting - a.score_setting)[0];
  };

  const onSave = () => {
    if (window.confirm(t("SaveChanges"))) {
      if (itemPoints.length) {
        mutate();
      } else {
        window.alert(t("ErrorMessage.NoDataToModify"));
      }
    }
  };

  const { data, isLoading, refetch } = useQuery(
    ["Points", selected.title],
    getExamPoints,
    {
      enabled: !!selected.title.length,
      refetchOnWindowFocus: true,
    }
  );
  const { mutate, isSuccess } = useMutation(
    () =>
      patchExamPoints({
        skillName: selected.title,
        modifyDataArray: itemPoints,
      }),
    {
      onSuccess: () => {
        refetch();
        setPoints([]);
        alert(t("Saved"));
      },
      onError: (e) => {
        console.log(e);
      },
    }
  );

  //version 가져오기
  const versionOptionMemo = useMemo(() => {
    const filteredData = filterByTitle(data, selected.title);
    const option = filteredData.map((data) => {
      return data.score_setting;
    });
    return option;
  }, [data, selected.title]);

  //버전 배열
  useEffect(() => {
    setVersionOption(versionOptionMemo);
  }, [versionOptionMemo]);

  //배점 초기화
  useEffect(() => {
    if (!hasFetchedData) {
      refetch();
      setHasFetchedData(true);
    }
  }, [refetch, selected.title, hasFetchedData]);

  //mutation이 성공적으로 마무리되면 수정된 데이터 가져오기
  useEffect(() => {
    if (isSuccess) {
      const filteredData = filterByTitle(data, selected.title);
      const latestExam = latestByExam(filteredData);
      setExam((prev) => ({
        ...prev,
        list: latestExam.maxScoreDataList,
        version: latestExam.score_setting,
      }));
      setSelected((prev) => ({
        ...prev,
        score_setting: latestExam.score_setting,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  //배점 최신화
  useEffect(() => {
    const filteredData = filterByTitle(data, selected.title);
    setExamList(filteredData);
  }, [data, refetch, selected.title]);

  //타이틀 선택
  const selectedExam = async (title) => {
    const filteredData = await filterByTitle(data, title);
    const latestExam = await latestByExam(filteredData);
    setExam((prev) => ({
      ...prev,
      list: latestExam.maxScoreDataList,
      version: latestExam.score_setting,
    }));
    setExamList(filteredData);
    setSelected((prev) => ({
      ...prev,
      title,
      score_setting: latestExam?.score_setting,
    }));
  };

  //버전 선택
  const selectedKey = async (version) => {
    const { score_setting } = latestByExam(examList);
    setColumns(selectColumns(version, score_setting));
    const selectedExam = await examList?.find(
      (examData) => examData.score_setting === +version
    );
    setExam((prev) => ({
      ...prev,
      list: selectedExam.maxScoreDataList,
      version,
    }));
    const selectExam = examList?.find(
      ({ score_setting }) => score_setting === version
    );
    setSelected((prev) => ({
      ...prev,
      score_setting: selectExam.score_setting,
    }));
  };

  const selectColumns = (selectedVersion, latestVersion) => {
    return selectedVersion === latestVersion
      ? POINT_COLUMNS
      : READ_POINT_COLUMNS;
  };

  return (
    <ManagerForm
      type={"points"}
      titleText={t("ManageScoring")}
      itemText={t("SearchScoring")}
      titleHeader={
        <Header>
          <div style={{ display: "flex", gap: "10px" }}>
            {!isLoading && (
              <>
                <Select
                  value={selected.title}
                  onClick={selectedExam}
                  options={TITLE_MAPPING}
                  title={t("CoreSkills")}
                  type={"title"}
                />
                {selected.title && (
                  <Select
                    value={selected.score_setting}
                    onClick={selectedKey}
                    options={versionOption}
                    title={"version"}
                    small
                  />
                )}
              </>
            )}
          </div>

          {selected.title && !isLoading && (
            <ItemButton onClick={onSave}>
              <StyledUpdateIcon />
              <span>Update</span>
            </ItemButton>
          )}
        </Header>
      }
    >
      {isLoading ? (
        <Loading>Loading...</Loading>
      ) : selected.title ? (
        <BasicTable columns={columns} data={exam.list} title={selected.title} />
      ) : (
        <ErrorCell option={"default"} />
      )}
    </ManagerForm>
  );
};

export default ExamPointView;
