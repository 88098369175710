import React from "react";
import { Container, Item, Title } from "./Styled";

const TitleHeader = ({ titleText, itemText }) => {
  return (
    <Container>
      <Title>{titleText}</Title>
      <Item>{itemText}</Item>
    </Container>
  );
};

export default TitleHeader;
