import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import NotFound from "pages/NotFound";
import { Auth, Manager } from "routes";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<NotFound />} />
        <Route path="/" element={<Navigate replace to="/auth" />} />
        <Route exact path="/auth/*" element={<Auth />} />
        <Route exact path="/manager/*" element={<Manager />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
