import { t } from "i18next";
import React from "react";
import { LANGUAGES_SWAP_MAPPING } from "services/mapping";
import { StyledGlobalIcon, StyledManualIcon } from "assets/images/icon/Svg";
import { useTranslation } from "react-i18next";
import { downloadManual } from "services/api";
import FileSaver from "file-saver";
import { List, ListContainer, ListItem, Navigation } from "./Styled";
import {
  LocaleButton,
  ManualButton,
} from "components/Common/Button/CustomButton";
import { useMutation } from "react-query";
import { useClickOutside } from "services/hooks/useClickOutSide";

const GlobalHeader = () => {
  const { i18n } = useTranslation();
  const [showList, setShowList] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const listRef = React.useRef(null);

  const { mutate } = useMutation(downloadManual, {
    onSuccess: (data) => {
      const fileName = t("Manual");
      FileSaver.saveAs(new Blob([data]), `${fileName}.pdf`);
    },
  });

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      mutate();
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  const { nativeName, code } = JSON.parse(localStorage.getItem("lng"));

  const handleButtonClick = () => {
    setShowList(!showList);
  };

  const handlerClick = (lng) => () => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", JSON.stringify(LANGUAGES_SWAP_MAPPING[lng]));
    setShowList(true);
    window.location.reload();
  };

  useClickOutside(listRef, () => setShowList(false));

  return (
    <Navigation>
      <ManualButton onClick={handleDownload}>
        <StyledManualIcon />
        <span>{isDownloading ? t("ManulaLoading") : t("Manual")}</span>
      </ManualButton>
      <LocaleButton onClick={handleButtonClick}>
        <StyledGlobalIcon />
        <span>{nativeName}</span>
      </LocaleButton>
      {showList && (
        <ListContainer ref={listRef}>
          <List>
            {Object.keys(LANGUAGES_SWAP_MAPPING).map((lng) => {
              const { Emoji, nativeName } = LANGUAGES_SWAP_MAPPING[lng];
              return (
                <ListItem
                  key={lng}
                  name={lng}
                  active={code}
                  onClick={handlerClick(lng)}
                >
                  {Emoji} | {nativeName}
                </ListItem>
              );
            })}
          </List>
        </ListContainer>
      )}
    </Navigation>
  );
};

export default GlobalHeader;
