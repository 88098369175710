import { t } from "i18next";
import React, { useEffect } from "react";
import {
  StyledAdminIcon,
  StyledTestEvalIcon,
  StyledPointsIcon,
} from "assets/images/icon/Svg";
import Logo from "./Logo";
import NavItem from "./NavItem";
import { Nav, NavItemList, NavProfile } from "./Styled";
import Profile from "./Profile";
import Name from "./Name";
import LogoutButton from "../Button/LogoutButton";

const Navbar = ({ setWidth }) => {
  const ref = React.useRef();
  const menuItems = [
    {
      text: t("ManageStudent"),
      icon: <StyledAdminIcon width={"1.8rem"} height={"1.8rem"} />,
      path: "admin",
    },
    {
      text: t("ManageTest"),
      icon: <StyledTestEvalIcon width={"1.8rem"} height={"1.8rem"} />,
      path: "eval",
    },
    {
      text: t("ManageRecord"),
      icon: <StyledTestEvalIcon width={"1.8rem"} height={"1.8rem"} />,
      path: "records",
    },
    {
      text: t("ManageScoring"),
      icon: <StyledPointsIcon width={"1.8rem"} height={"1.8rem"} />,
      path: "points",
    },
  ];

  useEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, [setWidth]);

  return (
    <Nav ref={ref}>
      <Logo />
      <Profile />
      <NavItemList>
        {menuItems.map((item, index) => (
          <NavItem
            key={index}
            path={item.path}
            icon={item.icon}
            text={item.text}
          />
        ))}
      </NavItemList>
      <NavProfile isManagerForm={true}>
        <Name />
        <LogoutButton />
      </NavProfile>
    </Nav>
  );
};

export default Navbar;
